.aspect-ratio {
  height: 0;
  width: 100%;
  overflow: hidden;
  /* force container into 16:9 aspect ratio, but cap at viewport height (minus vertical padding) */
  padding-top: min(100% * 9 / 16, calc(100vh - 2 * var(--s1)));
  position: relative;
}

.aspect-ratio-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.empty-player {
  display: flex;
  align-items: center;
  font-size: 1.6em;
  justify-content: center;
  border-radius: var(--radius);
  border: var(--border) solid var(--card);
  width: 100%;
  height: 100%;
}
