.input-group {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  width: 100%;
  background-color: var(--card-alt);
  border-radius: 0 0 var(--radius) var(--radius);
}

.input-text {
  width: 100%;
  padding: var(--s0);
  padding-right: 36px;
  margin-right: -36px;
  color: var(--fg);
  background-color: #444444;
  border: none;
  border-radius: 0 0 var(--radius) var(--radius);
}

.input-send {
  background-color: unset;
}
