.thumbnail {
  min-width: 35%;
  max-width: 35%;
  margin-right: var(--s0);
}

.placeholder {
  color: var(--fg-light);
  aspect-ratio: 16/9;
  border: 1px solid var(--card-alt);
  display: flex;
  justify-content: center;
  align-items: center;
}
