:root {
  --s0: 0.75rem;
  --s1: 2rem;
  --bg: #0a0a0a;
  --card: #222222;
  --card-alt: #444444;
  --hover: #555555;
  --fg: #dddddd;
  --fg-light: #888888;
  --radius: 5px;
  --border: 16px;
}

html {
  color: var(--fg);
  background-color: var(--bg);

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  height: 100vh;
}

button {
  cursor: pointer;
  color: var(--fg);
  background-color: var(--card-alt);
  border: none;
  padding: var(--s0);
  border-radius: var(--radius);
}

button:hover:not(:disabled) {
  background-color: var(--hover);
}

button:disabled {
  cursor: default;
}

#root {
  height: 100%;
}
