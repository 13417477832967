.bookmarklet {
  display: inline-block;
  color: var(--fg);
  background-color: var(--card-alt);
  padding: var(--s0);
  border-radius: var(--radius);
  text-decoration: none;
}

.shortcuts {
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  gap: var(--s0);
}

.shortcuts > li {
  list-style: none;
  flex: 0 1 400px;
}

.shortcuts > li > *:last-child {
  margin-left: var(--s0);
}

kbd {
  background-color: var(--card-alt);
  padding: 2px var(--s0);
  border-radius: var(--radius);
  border: 1px solid var(--fg-light);

  display: inline-block;

  font-size: 1.1em;
  line-height: 1.5em;
}
