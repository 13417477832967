.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.with-sidebar {
  flex-shrink: 0;
  overflow: hidden;
  padding: var(--s1);

  display: flex;
  gap: var(--s1);
  flex-wrap: wrap;
  justify-content: center;
}

.with-sidebar > :nth-child(1) {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 70%;
}

.with-sidebar > :nth-child(2) {
  flex-grow: 0;
  flex-basis: 30rem;
}

.aside {
  /* i'm using the view width in a max-height calculation and you can't stop me */
  max-height: min(
    max(
      /* video height based on view width */
        calc((100vw - 3 * var(--s1) - 30rem) * 9 / 16),
      30rem /* ensure minimum height when control is below video */
    ),
    calc(100vh - 2 * var(--s1))
      /* cap to view height (minus vertical padding) */
  );
}

.footer {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  padding: calc(var(--s0) / 2);
}

.social {
  padding: calc(var(--s0) / 2);
  color: var(--card-alt);
}

.social:hover {
  color: var(--hover);
}
