.queue {
  min-height: 0;
  flex-grow: 1;
  padding: 0 var(--s1);
  margin-bottom: calc(-1 * var(--s1));

  background-color: var(--card);
  border-radius: var(--radius) var(--radius) 0 0;
  overflow-y: auto;

  display: flex;
  flex-flow: column nowrap;
}

.queue-header {
  position: sticky;
  top: 0;

  /* to render above font awesome layered icons */
  z-index: 1;

  padding-top: calc(var(--s1) - var(--s0));
  padding-bottom: var(--s0);
  background-color: var(--card);

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.title {
  margin: 0;
  margin: var(--s0) 0;
  display: flex;
  align-items: center;
}

.status {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.skip {
  margin: calc(-1 * var(--s0)) 0;
}

.getlink {
  padding: var(--s0);
  margin: calc(var(--s0) * -1);
  cursor: pointer;
}

.queue-list {
  margin-top: var(--s0);
  margin-bottom: var(--s1);
}

.queue-list > * {
  margin-bottom: var(--s0);
}

.queue-list > *:last-child {
  margin-bottom: 0;
}

.queue-item {
  display: flex;
  flex-flow: row nowrap;
  cursor: grab;
  align-items: flex-start;
}

.remove {
  display: inline-flex;
  align-items: flex-start;
  visibility: hidden;
  background-color: unset;
  padding: 0.5rem;
}

.queue-item:hover > .remove {
  visibility: visible;
}

.video-info {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-right: var(--s0);
  align-items: flex-start;
}

.hostname {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: var(--fg-light);
}
