dialog {
  width: 900px;
  max-width: 95vw;
  height: 800px;
  max-height: 95vh;

  padding: 0;

  transition-property: transform;
  transition-duration: 100ms;
  transition-timing-function: ease-in;
  transform: scale(0.1);

  border: none;
  border-radius: var(--radius);

  color: var(--fg);
  background-color: var(--card);
}

dialog.animate {
  transition-timing-function: ease-out;
  transform: scale(1);
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.6);
}

dialog div {
  width: 100%;
  min-height: 100%;
  padding: var(--s1);
}

dialog .close {
  background-color: unset;
  position: fixed;
  padding: var(--s1);
  top: var(--s0);
  right: var(--s0);
  cursor: pointer;
}

dialog .close:hover {
  background-color: unset;
}

dialog h1 {
  margin-top: 0;
}
