.infobox {
  padding: var(--s1);
  background-color: var(--card);
  border-radius: var(--radius);

  display: flex;
  flex-flow: row wrap;
  column-gap: var(--s0);
  row-gap: var(--s1);
  align-items: center;
  justify-content: space-between;
}

.infobox.success {
  background-color: #147447;
}

.infobox.info {
  background-color: #005187;
}

.infobox.error {
  background-color: #880011;
}

.notification {
  justify-self: start;
}

.remaining {
  background-color: var(--card);
  padding: var(--s0);
  margin: calc(-1 * var(--s0)) 0;
  border-radius: var(--radius);
}

.infobox.success .remaining {
  background-color: #0d492c;
}

.infobox.info .remaining {
  background-color: #003254;
}

.infobox.error .remaining {
  background-color: #55000b;
}

.connections {
  flex: 1 0 auto;
}

.copylink {
  flex: 0 0 auto;
  margin: calc(-1 * var(--s0)) auto;
}

.about {
  margin: calc(-1 * var(--s0)) 0;
}
